import '../scss/app.scss'; // Vite requires css import in app.js

import './modules/lazyload';
import './modules/set-vh';
// import './modules/set-footer-height';
import './modules/nav';
import './modules/nav-desktop';
import { $ } from './helpers/query-selector';

async function init() {
  // import('instant.page');

  if ($('.js-video-hero')) import('./modules/video-hero');
  if ($('.js-image-hero')) import('./modules/image-hero');
  if ($('.js-company-hero')) import('./modules/company-hero');
  if ($('.js-services-hero')) import('./modules/services-hero');
  if ($('.js-services-teaser')) import('./modules/services-teaser');
  if ($('.js-map')) import('./modules/map');
  if ($('.js-slideshow')) import('./modules/slideshow');
  if ($('.js-pie-chart')) import('./modules/pie-chart');
  if ($('script[data-consent-required]')) import('./modules/cookie-consent');

  if ($('.js-logo-list'))
    import('./modules/logo-list').then(module => module.default());
  if ($('.js-startup-list'))
    import('./modules/startup-list').then(module => module.default());
  if ($('.js-textarea'))
    import('./modules/textarea').then(module => module.default());
  if ($('.js-form')) import('./modules/form').then(module => module.default());
  if ($('.js-accordion'))
    import('./modules/accordion').then(module => module.default());
}

init();
