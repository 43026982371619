const doc = document.documentElement;

function onResize(callback) {
  let ticking = false;
  let prevClientHeight = doc.clientHeight;

  function resize() {
    if (ticking) return;

    window.requestAnimationFrame(() => {
      const newClientHeight = doc.clientHeight;

      if (newClientHeight !== prevClientHeight) {
        prevClientHeight = newClientHeight;

        callback();
      }

      ticking = false;
    });

    ticking = true;
  }

  window.addEventListener('resize', resize);
  window.addEventListener('orientationchange', resize);
}

function setVh() {
  const vh = doc.clientHeight * 0.01;
  doc.style.setProperty('--vh', `${vh}px`);
}

setVh();
onResize(setVh);
