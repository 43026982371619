import transitionEnd from '../helpers/transition-end';
import { $, $$ } from '../helpers/query-selector';
import { mq, reducedMotion } from '../helpers/variables';
import { gsap } from 'gsap/all';

const navWrapper = $('.js-nav-wrapper');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navElements = [...$$('.js-nav-mobile li'), $('.js-nav-lang')];
const navIconActiveClass = 'o-nav-icon--active';
const navWrapperActiveClass = 'c-nav__wrapper--active';
const navWrapperAnimClass = 'c-nav__wrapper--anim';
let navVisible = false;

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);

  navWrapper.classList.add(navWrapperActiveClass);
  navWrapper.classList.add(navWrapperAnimClass);

  // Move in
  if (reducedMotion.matches) return;

  gsap.fromTo(
    navElements,
    {
      y: 30,
      opacity: 0
    },
    {
      y: 0,
      opacity: 1,
      duration: 0.4,
      stagger: 0.025,
      ease: 'circ.out'
    }
  );
}

async function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);

  navWrapper.classList.remove(navWrapperActiveClass);
  await transitionEnd(navWrapper, 'opacity');
  navWrapper.classList.remove(navWrapperAnimClass);
}

function init() {
  navToggle.addEventListener('click', () => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav on resize
  mq.ms.addListener(e => {
    if (e.matches) hideNav();
  });
}

init();
