import { $, $$ } from '../helpers/query-selector';

const navDesktop = $('.js-nav-desktop');

function alignArrow(toggle, subList) {
  const toggleRect = toggle.getBoundingClientRect();

  const toggleLeft = toggleRect.left;
  const toggleCenter = toggleLeft + toggleRect.width / 2;

  subList.style.setProperty('--align', Math.round(toggleCenter) + 'px');
}

function showSubList(toggle, subList) {
  // Align arrow
  alignArrow(toggle, subList);

  const subLists = $$('.js-nav-sublist', navDesktop);

  for (const list of subLists) {
    if (list.classList.contains('visible')) list.classList.remove('visible');
  }

  toggle.setAttribute('aria-expanded', 'true');
  subList.classList.add('visible');
}

function hideSubList(toggle, subList) {
  toggle.setAttribute('aria-expanded', 'false');
  subList.classList.remove('visible');
}

function init() {
  if (!navDesktop) return;

  const rubrics = $$('.js-nav-rubric', navDesktop);

  for (const rubric of rubrics) {
    const toggle = $('.js-nav-toggle-sublist', rubric);
    const subList = $('.js-nav-sublist', rubric);

    // Hover behaviour
    toggle.addEventListener('mouseover', () => {
      showSubList(toggle, subList);
    });

    rubric.addEventListener('mouseleave', () => {
      if (subList.classList.contains('visible')) hideSubList(toggle, subList);
    });

    // Keydown behaviour
    toggle.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        subList.classList.contains('visible')
          ? hideSubList(toggle, subList)
          : showSubList(toggle, subList);
      }
    });
  }
}

init();
